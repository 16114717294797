<template>
  <c-form-control
    :is-disabled="isDisabled"
    :is-required="isRequired"
    :is-invalid="isInvalid"
    :mb="isWithoutMarginBottom ? '0' : ['16px', '24px']"
    :is-read-only="isReadOnly"
    :w="fullWidth ? '100%' : 'inherit'"
  >
    <c-form-label
      :font-size="['14px', '16px']"
      :color="value ? '#008C81' : '#555'"
      font-weight="400"
      font-family="Roboto"
    >
      {{ label }}
      <c-box
        v-if="!isRequired"
        as="span"
        font-family="Roboto"
        font-weight="400"
        color="#C4C4C4"
        :font-size="['12px', '14px']"
      >
        (Optional)
      </c-box>
    </c-form-label>
    <c-textarea 
      v-if="type == 'textarea'" 
      v-model="modelValue"
      :height="['48px', '62px']"
      :font-size="['14px', '18px']"
      :color="value ? '#008C81' : '#555'"
      :font-weight="value ? '500' : '400'"
      :placeholder="placeholder ? placeholder : label"
      @blur="onBlur"
    />
    <c-box
      v-else-if="type === 'date'"
      style="position: relative;"
      :height="['48px', '62px']"
    >
      <DatePicker
        v-model="modelValue"
        :input-class="{
          default: true,
          err: isInvalid,
        }"
        value-type="YYYY-MM-DD"
        format="DD MMMM YYYY"
        type="date"
        :placeholder="placeholder"
        :clearable="false"
        :disabled="isDisabled"
        editable="false"
        lang="id"
        :disabled-date="(date) => date > new Date()"
        @blur="onBlur"
      >
        <template #icon-calendar>
          &nbsp;
        </template>
      </DatePicker>
    </c-box>
    <c-input-group 
      v-else
    >
      <c-input-left-element
        v-if="leftElement || iconLeftElement"
        :height="['48px', '62px']"
        :font-size="['14px', '18px']"
        :color="value ? '#008C81' : '#555'"
        :font-weight="value ? '500' : '400'"
        h="100%"
      >
        {{ iconLeftElement ? "" : leftElement }}
        <c-box
          v-if="iconLeftElement"
          :h="['20px', '25px']"
          :w="['20px', '25px']"
          :min-h="['20px', '25px']"
          :min-w="['20px', '25px']"
        >
          <inline-svg
            :src="iconLeftElement"
            height="100%"
            width="100%"
            :fill="iconLeftElementColor"
          />
        </c-box>
      </c-input-left-element>
      <c-input
        v-model="modelValue"
        :type="type_ || 'text'"
        :height="['48px', '62px']"
        :font-size="['14px', '18px']"
        :color="value ? '#008C81' : '#555'"
        :font-weight="value ? '500' : '400'"
        :placeholder="placeholder ? placeholder : label"
        @blur="onBlur"
      />
      <c-input-right-element
        v-if="rightElement || iconRightElement || type === 'password'"
        :height="['48px', '62px']"
        :font-size="['14px', '18px']"
        :color="value ? '#008C81' : '#555'"
        :font-weight="value ? '500' : '400'"
        h="100%"
        mx="1rem"
        :cursor="type === 'password' ? 'pointer' : 'auto'"
      >
        {{ iconRightElement ? "" : rightElement }}
        <c-text
          as="span"
          :h="['20px', '25px']"
          :w="['20px', '25px']"
          :min-h="['20px', '25px']"
          :min-w="['20px', '25px']"
        >
          <inline-svg
            v-if="iconRightElement"
            :src="iconRightElement"
            height="100%"
            width="100%"
            fill="#323232"
          />
          <inline-svg
            v-if="type === 'password' && type_ === 'password'"
            :src="require('@/assets/ic-show-password.svg')"
            height="100%"
            width="100%"
            fill="#323232"
            user-select="none"
            @click="toggleShowPassword"
          />
          <inline-svg
            v-if="type === 'password' && type_ === 'text'"
            :src="require('@/assets/ic-hidden-password.svg')"
            height="100%"
            width="100%"
            fill="#323232"
            user-select="none"
            @click="toggleShowPassword"
          />
        </c-text>
      </c-input-right-element>
    </c-input-group>
    <c-form-helper-text
      v-if="isHelper"
      :font-size="['12px', '16px']"
      :font-weight="['400', '400']"
      d="flex"
      gap="8px"
      align-items="center"
      :color="helperTextColor"
    >
      <c-box
        display="flex"
        gap="8px"
        align-items="center"
        :cursor="hasHelperTextClick ? 'pointer' : 'auto'"
        @click="$emit('helper-text-click')"
      >
        <c-box
          v-if="helperTextLogo"
          :min-height="['13px', '16px']"
          :min-width="['13px', '16px']"
          :height="['13px', '16px']"
          :width="['13px', '16px']"
        >
          <inline-svg
            :src="helperTextLogo"
            height="100%"
            width="100%"
            :fill="helperTextColor"
          />
        </c-box>
        {{ helperText }}
      </c-box>
    </c-form-helper-text>

    <c-form-helper-text
      v-if="!isDataEmpty(invalidText) && isInvalid"
      :font-size="['12px', '16px']"
      :font-weight="['400', '400']"
      d="flex"
      flex-direction="column"
      gap="8px"
      color="#D32737"
    >
      <c-text
        v-for="(invalidTextValue, invalidTextKey) in invalidText_"
        :key="invalidTextKey"
      >
        &#9679; {{ invalidTextValue }}
      </c-text>
    </c-form-helper-text>
  </c-form-control>
</template>

<script>
import { formatDateV2 } from '@/utils/format-date'
import { isDataEmpty } from '@/utils/is-data-empty'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/id'
import 'vue2-datepicker/index.css'

export default {
  name: 'FormInput',
  components: {
    DatePicker,
  },
  props: {
    type: {
      type: String,
      default: 'input',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    invalidText: {
      type: [String, Object],
      default: '',
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      required: true,
    },
    isHelper: {
      type: Boolean,
      default: false,
    },
    helperText: {
      type: String,
      default: '',
    },
    leftElement: {
      type: String,
      default: '',
    },
    rightElement: {
      type: String,
      default: '',
    },
    iconLeftElement: {
      type: String,
      default: '',
    },
    iconLeftElementColor: {
      type: String,
      default: 'black',
    },
    iconRightElement: {
      type: String,
      default: '',
    },
    isWithoutMarginBottom: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    helperTextType: {
      type: String,
      default: '',
    },
    helperTextColor: {
      type: String,
      default: '',
    },

  },
  data() {
    return {
      type_: this.type,
    }
  },
  computed: {
    invalidText_() {
      if (typeof this.invalidText === 'string') {
        return [this.invalidText]
      }
      return this.invalidText
    },
    helperTextLogo() {
      if (this.helperTextType === 'warn') {
        return require('@/assets/icons/icon-circle-warn.svg')
      }
      if (this.helperTextType === 'questionmark') {
        return require('@/assets/icons/icon-circle-questionmark.svg')
      }
      return ''
    },
    hasHelperTextClick() {
      return this.$listeners['helper-text-click']
    },
    modelValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
        this.$emit('trigger-change', val)
      },
    },
  },
  methods: {
    isDataEmpty,
    formatDateV2,
    onBlur() {
      this.$emit('blur')
    },
    toggleShowPassword() {
      if (this.type_ === 'password') {
        this.type_ = 'text'
        return 
      }
      this.type_ = 'password'
    },
  },
}
</script>

<style scoped>
::v-deep .default {
  cursor: pointer;
  font-size: 14px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  border: 1px solid rgba(136, 136, 136, 0.2);
  height: 100%;
  width: 100%;
  border-radius: 8px;
  padding: 0 20px;
  color: #008C81;
}

@media (min-width: 768px) {
  ::v-deep .default {
    font-size: 18px;
  }
}

::v-deep .default:focus-visible {
  outline: none;
  //box-shadow: 0px 0px 0px 2px #008C81 inset;
}

::v-deep .err {
  outline: 2px solid #e66673;
  color: #D32737;
}

::v-deep .err:focus-visible {
  outline: 2px solid #e66673;
}

::v-deep .default[disabled] {
  opacity: 0.4;
  cursor: not-allowed;
}

::v-deep .disabled .mx-icon-calendar {
  opacity: 0.4;
  cursor: not-allowed;
}

::v-deep .mx-datepicker, ::v-deep .mx-input-wrapper {
  width: 100%;
  height: 100%;
}
</style>
